(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name championship.classification.controller:ClassificationCtrl
   *
   * @description
   *
   */
  angular
      .module('neo.public.classification')
      .controller('ClassificationCtrl', ClassificationCtrl);

  function ClassificationCtrl(classification, championship, matchDay, teams, genders, sports, categories, ChampionshipMatchesPublic, ChampionshipClassification, $stateParams, $filter, $q) {
    var vm = this;
    vm.ctrlName = 'ClassificationCtrl';

    vm.championship = championship;

    vm.category = $filter('translate')('CATEGORIES.' + findItemById2(categories, vm.championship.categoryId).name);
    vm.sport = $filter('translate')('SPORTS.' + findItemById2(sports, vm.championship.sportId).name);
    vm.gender = $filter('translate')(findItemById2(genders, vm.championship.genderId).name);

    vm.paginationLabel = {
      page: $filter('translate')('CHAMPIONSHIPS.MATCH_DAY_LABEL'),
      rowsPerPage: '',
      of: $filter('translate')('OF')
    };
    vm.maxMatches = (teams.length % 2) ? teams.length * vm.championship.rounds : (teams.length) - 1 * vm.championship.rounds;
    vm.matchesPerPage = 1;
    vm.currentMatch = matchDay.number;

    vm.classification = classification;
    parseClassifications(vm.classification);

    vm.classificationHeaders = [
      'CHAMPIONSHIPS.TEAM',
      'CHAMPIONSHIPS.POINTS',
      'CHAMPIONSHIPS.PLAYED_GAMES',
      'CHAMPIONSHIPS.WINS',
      'CHAMPIONSHIPS.TIES',
      'CHAMPIONSHIPS.LOSES',
      'CHAMPIONSHIPS.SCORE_FOR',
      'CHAMPIONSHIPS.SCORE_AGAINST',
      'CHAMPIONSHIPS.PENALTIES'
    ];

    vm.matchDay = matchDay;
    parseMatches(vm.matchDay);

    vm.matchHeaders = [
      'CHAMPIONSHIPS.LOCAL_TEAM',
      'CHAMPIONSHIPS.LOCAL_SCORE',
      'CHAMPIONSHIPS.VISITOR_SCORE',
      'CHAMPIONSHIPS.VISITOR_TEAM'
    ];

    vm.getNewPage = function (page) {
      var deferredClassification = $q.defer();
      vm.promiseClassification = deferredClassification.promise;
      ChampionshipClassification.query({id: $stateParams.championshipId, matchday: page}, function (data) {
        vm.classification = data;
        parseClassifications(vm.classification);
        deferredClassification.resolve();
      });
      var deferredMatches = $q.defer();
      vm.promiseMatches = deferredMatches.promise;
      ChampionshipMatchesPublic.get({id: $stateParams.championshipId, matchday: page}, function (data) {
        vm.matchDay = data;
        parseMatches(vm.matchDay);
        deferredMatches.resolve();
      });
    };

    function parseClassifications(classification) {
      angular.forEach(classification, function (teamResult) {
        teamResult.teamName = findItemById(teams, 'id', teamResult.teamId).name;
      });
    }

    function parseMatches(matchDay) {
      angular.forEach(matchDay.matches, function (match) {
        match.localTeam = match.localTeamId !== null
          ? findItemById(teams, 'id', match.localTeamId).name
            : $filter('translate')('CALENDAR.REST');
        match.localScore = match.localScore !== null ? match.localScore : '-';
        match.visitorTeam = match.visitorTeamId !== null
          ? findItemById(teams, 'id', match.visitorTeamId).name
            : $filter('translate')('CALENDAR.REST');
        match.visitorScore = match.visitorScore !== null ? match.visitorScore : '-';
      });
    }

    function findItemById(collection, fieldId, itemId) {
      return _.find(collection, function (i) {
        return i[fieldId] === itemId;
      });
    }
function findItemById2(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }
  }
}());
